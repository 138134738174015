export const GET_SHIP_CONTAINER_TRAFFICS = "GET_SHIP_CONTAINER_TRAFFICS";
export const GET_SHIP_CONTAINER_TRAFFICS_SUCCESS =
  "GET_SHIP_CONTAINER_TRAFFICS_SUCCESS";
export const GET_SHIP_CONTAINER_TRAFFICS_FAIL = "GET_SHIP_CONTAINER_TRAFFICS_FAIL";
export const ADD_SHIP_CONTAINER_TRAFFIC = "ADD_SHIP_CONTAINER_TRAFFIC";
export const ADD_SHIP_CONTAINER_TRAFFIC_SUCCESS = "ADD_SHIP_CONTAINER_TRAFFIC_SUCCESS";
export const ADD_SHIP_CONTAINER_TRAFFIC_FAIL = "ADD_SHIP_CONTAINER_TRAFFIC_FAIL";
export const BULK_UPLOAD_SHIP_CONTAINER_TRAFFIC = "BULK_UPLOAD_SHIP_CONTAINER_TRAFFIC";
export const BULK_UPLOAD_SHIP_CONTAINER_TRAFFIC_SUCCESS =
  "BULK_UPLOAD_SHIP_CONTAINER_TRAFFIC_SUCCESS";
export const BULK_UPLOAD_SHIP_CONTAINER_TRAFFIC_FAIL =
  "BULK_UPLOAD_SHIP_CONTAINER_TRAFFIC_FAIL";
export const EDIT_SHIP_CONTAINER_TRAFFIC = "EDIT_SHIP_CONTAINER_TRAFFIC";
export const EDIT_SHIP_CONTAINER_TRAFFIC_SUCCESS =
  "EDIT_SHIP_CONTAINER_TRAFFIC_SUCCESS";
export const EDIT_SHIP_CONTAINER_TRAFFIC_FAIL = "EDIT_SHIP_CONTAINER_TRAFFIC_FAIL";
export const DELETE_SHIP_CONTAINER_TRAFFIC = "DELETE_SHIP_CONTAINER_TRAFFIC";
export const DELETE_SHIP_CONTAINER_TRAFFIC_SUCCESS =
  "DELETE_SHIP_CONTAINER_TRAFFIC_SUCCESS";
export const DELETE_SHIP_CONTAINER_TRAFFIC_FAIL = "DELETE_SHIP_CONTAINER_TRAFFIC_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
