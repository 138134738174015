export const GET_GROSS_DOMESTIC_PRODUCT = "GET_GROSS_DOMESTIC_PRODUCT";
export const GET_GROSS_DOMESTIC_PRODUCT_SUCCESS =
  "GET_GROSS_DOMESTIC_PRODUCT_SUCCESS";
export const GET_GROSS_DOMESTIC_PRODUCT_FAIL =
  "GET_GROSS_DOMESTIC_PRODUCT_FAIL";
export const ADD_GROSS_DOMESTIC_PRODUCT = "ADD_GROSS_DOMESTIC_PRODUCT";
export const ADD_GROSS_DOMESTIC_PRODUCT_SUCCESS =
  "ADD_GROSS_DOMESTIC_PRODUCT_SUCCESS";
export const ADD_GROSS_DOMESTIC_PRODUCT_FAIL =
  "ADD_GROSS_DOMESTIC_PRODUCT_FAIL";
export const BULK_UPLOAD_GROSS_DOMESTIC_PRODUCT =
  "BULK_UPLOAD_GROSS_DOMESTIC_PRODUCT";
export const BULK_UPLOAD_GROSS_DOMESTIC_PRODUCT_SUCCESS =
  "BULK_UPLOAD_GROSS_DOMESTIC_PRODUCT_SUCCESS";
export const BULK_UPLOAD_GROSS_DOMESTIC_PRODUCT_FAIL =
  "BULK_UPLOAD_GROSS_DOMESTIC_PRODUCT_FAIL";
export const EDIT_GROSS_DOMESTIC_PRODUCT = "EDIT_GROSS_DOMESTIC_PRODUCT";
export const EDIT_GROSS_DOMESTIC_PRODUCT_SUCCESS =
  "EDIT_GROSS_DOMESTIC_PRODUCT_SUCCESS";
export const EDIT_GROSS_DOMESTIC_PRODUCT_FAIL =
  "EDIT_GROSS_DOMESTIC_PRODUCT_FAIL";
export const DELETE_GROSS_DOMESTIC_PRODUCT = "DELETE_GROSS_DOMESTIC_PRODUCT";
export const DELETE_GROSS_DOMESTIC_PRODUCT_SUCCESS =
  "DELETE_GROSS_DOMESTIC_PRODUCT_SUCCESS";
export const DELETE_GROSS_DOMESTIC_PRODUCT_FAIL =
  "DELETE_GROSS_DOMESTIC_PRODUCT_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
