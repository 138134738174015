export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";
export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL";
export const EDIT_ROLE = "EDIT_ROLE";
export const EDIT_ROLE_SUCCESS =
  "EDIT_ROLES_SUCCESS";
export const EDIT_ROLE_FAIL = "EDIT_ROLE_FAIL";
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS =
  "DELETE_ROLES_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
