export const GET_AIR_TRANSPORT_DATA = "GET_AIR_TRANSPORT_DATA";
export const GET_AIR_TRANSPORT_DATA_SUCCESS = "GET_AIR_TRANSPORT_DATA_SUCCESS";
export const GET_AIR_TRANSPORT_DATA_FAIL = "GET_AIR_TRANSPORT_DATA_FAIL";
export const ADD_AIR_TRANSPORT_DATA = "ADD_AIR_TRANSPORT_DATA";
export const ADD_AIR_TRANSPORT_DATA_SUCCESS = "ADD_AIR_TRANSPORT_DATA_SUCCESS";
export const ADD_AIR_TRANSPORT_DATA_FAIL = "ADD_AIR_TRANSPORT_DATA_FAIL";
export const EDIT_AIR_TRANSPORT_DATA = "EDIT_AIR_TRANSPORT_DATA";
export const EDIT_AIR_TRANSPORT_DATA_SUCCESS =
  "EDIT_AIR_TRANSPORT_DATA_SUCCESS";
export const EDIT_AIR_TRANSPORT_DATA_FAIL = "EDIT_AIR_TRANSPORT_DATA_FAIL";
export const DELETE_AIR_TRANSPORT_DATA = "DELETE_AIR_TRANSPORT_DATA";
export const DELETE_AIR_TRANSPORT_DATA_SUCCESS =
  "DELETE_AIR_TRANSPORT_DATA_SUCCESS";
export const DELETE_AIR_TRANSPORT_DATA_FAIL = "DELETE_AIR_TRANSPORT_DATA_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
