export const GET_ROLLING_STOCKS = "GET_ROLLING_STOCKS";
export const GET_ROLLING_STOCKS_SUCCESS =
  "GET_ROLLING_STOCKS_SUCCESS";
export const GET_ROLLING_STOCKS_FAIL = "GET_ROLLING_STOCKS_FAIL";
export const ADD_ROLLING_STOCK = "ADD_ROLLING_STOCK";
export const ADD_ROLLING_STOCK_SUCCESS =
  "ADD_ROLLING_STOCK_SUCCESS";
export const ADD_ROLLING_STOCK_FAIL =
  "ADD_ROLLING_STOCK_FAIL";
export const EDIT_ROLLING_STOCK =
  "EDIT_ROLLING_STOCK";
export const EDIT_ROLLING_STOCK_SUCCESS =
  "EDIT_ROLLING_STOCK_SUCCESS";
export const EDIT_ROLLING_STOCK_FAIL =
  "EDIT_ROLLING_STOCK_FAIL";
export const DELETE_ROLLING_STOCK =
  "DELETE_ROLLING_STOCK";
export const DELETE_ROLLING_STOCK_SUCCESS =
  "DELETE_ROLLING_STOCK_SUCCESS";
export const DELETE_ROLLING_STOCK_FAIL =
  "DELETE_ROLLING_STOCK_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
