export const GET_MARITIME_ACADEMIES = "GET_MARITIME_ACADEMIES";
export const GET_MARITIME_ACADEMIES_SUCCESS = "GET_MARITIME_ACADEMIES_SUCCESS";
export const GET_MARITIME_ACADEMIES_FAIL = "GET_MARITIME_ACADEMIES_FAIL";
export const ADD_MARITIME_ACADEMY = "ADD_MARITIME_ACADEMY";
export const ADD_MARITIME_ACADEMY_SUCCESS = "ADD_MARITIME_ACADEMY_SUCCESS";
export const ADD_MARITIME_ACADEMY_FAIL = "ADD_MARITIME_ACADEMY_FAIL";
export const EDIT_MARITIME_ACADEMY = "EDIT_MARITIME_ACADEMY";
export const EDIT_MARITIME_ACADEMY_SUCCESS = "EDIT_MARITIME_ACADEMY_SUCCESS";
export const EDIT_MARITIME_ACADEMY_FAIL = "EDIT_MARITIME_ACADEMY_FAIL";
export const DELETE_MARITIME_ACADEMY = "DELETE_MARITIME_ACADEMY";
export const DELETE_MARITIME_ACADEMY_SUCCESS =
  "DELETE_MARITIME_ACADEMY_SUCCESS";
export const DELETE_MARITIME_ACADEMY_FAIL = "DELETE_MARITIME_ACADEMY_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
