/* PASSENGER ROAD TRANSPORT DATA */
export const GET_PASSENGER_ROAD_TRANSPORT_DATA =
  "GET_PASSENGER_ROAD_TRANSPORT_DATA";
export const GET_PASSENGER_ROAD_TRANSPORT_DATA_SUCCESS =
  "GET_PASSENGER_ROAD_TRANSPORT_DATA_SUCCESS";
export const GET_PASSENGER_ROAD_TRANSPORT_DATA_FAIL =
  "GET_PASSENGER_ROAD_TRANSPORT_DATA_FAIL";
export const ADD_PASSENGER_ROAD_TRANSPORT_DATA =
  "ADD_PASSENGER_ROAD_TRANSPORT_DATA";
export const ADD_PASSENGER_ROAD_TRANSPORT_DATA_SUCCESS =
  "ADD_PASSENGER_ROAD_TRANSPORT_DATA_SUCCESS";
export const ADD_PASSENGER_ROAD_TRANSPORT_DATA_FAIL =
  "ADD_PASSENGER_ROAD_TRANSPORT_DATA_FAIL";
export const EDIT_PASSENGER_ROAD_TRANSPORT_DATA =
  "EDIT_PASSENGER_ROAD_TRANSPORT_DATA";
export const EDIT_PASSENGER_ROAD_TRANSPORT_DATA_SUCCESS =
  "EDIT_PASSENGER_ROAD_TRANSPORT_DATA_SUCCESS";
export const EDIT_PASSENGER_ROAD_TRANSPORT_DATA_FAIL =
  "EDIT_PASSENGER_ROAD_TRANSPORT_DATA_FAIL";
export const DELETE_PASSENGER_ROAD_TRANSPORT_DATA =
  "DELETE_PASSENGER_ROAD_TRANSPORT_DATA";
export const DELETE_PASSENGER_ROAD_TRANSPORT_DATA_SUCCESS =
  "DELETE_PASSENGER_ROAD_TRANSPORT_DATA_SUCCESS";
export const DELETE_PASSENGER_ROAD_TRANSPORT_DATA_FAIL =
  "DELETE_PASSENGER_ROAD_TRANSPORT_DATA_FAIL";

/* FREIGHT ROAD TRANSPORT DATA */
export const GET_FREIGHT_ROAD_TRANSPORT_DATA =
  "GET_FREIGHT_ROAD_TRANSPORT_DATA";
export const GET_FREIGHT_ROAD_TRANSPORT_DATA_SUCCESS =
  "GET_FREIGHT_ROAD_TRANSPORT_DATA_SUCCESS";
export const GET_FREIGHT_ROAD_TRANSPORT_DATA_FAIL =
  "GET_FREIGHT_ROAD_TRANSPORT_DATA_FAIL";
export const ADD_FREIGHT_ROAD_TRANSPORT_DATA =
  "ADD_FREIGHT_ROAD_TRANSPORT_DATA";
export const ADD_FREIGHT_ROAD_TRANSPORT_DATA_SUCCESS =
  "ADD_FREIGHT_ROAD_TRANSPORT_DATA_SUCCESS";
export const ADD_FREIGHT_ROAD_TRANSPORT_DATA_FAIL =
  "ADD_FREIGHT_ROAD_TRANSPORT_DATA_FAIL";
export const EDIT_FREIGHT_ROAD_TRANSPORT_DATA =
  "EDIT_FREIGHT_ROAD_TRANSPORT_DATA";
export const EDIT_FREIGHT_ROAD_TRANSPORT_DATA_SUCCESS =
  "EDIT_FREIGHT_ROAD_TRANSPORT_DATA_SUCCESS";
export const EDIT_FREIGHT_ROAD_TRANSPORT_DATA_FAIL =
  "EDIT_FREIGHT_ROAD_TRANSPORT_DATA_FAIL";
export const DELETE_FREIGHT_ROAD_TRANSPORT_DATA =
  "DELETE_FREIGHT_ROAD_TRANSPORT_DATA";
export const DELETE_FREIGHT_ROAD_TRANSPORT_DATA_SUCCESS =
  "DELETE_FREIGHT_ROAD_TRANSPORT_DATA_SUCCESS";
export const DELETE_FREIGHT_ROAD_TRANSPORT_DATA_FAIL =
  "DELETE_FREIGHT_ROAD_TRANSPORT_DATA_FAIL";

  
export const CLEAR_MESSAGE = "CLEAR_MESSAGE"