export const GET_MARITIME_TRANSPORTS = "GET_MARITIME_TRANSPORTS";
export const GET_MARITIME_TRANSPORTS_SUCCESS =
  "GET_MARITIME_TRANSPORTS_SUCCESS";
export const GET_MARITIME_TRANSPORTS_FAIL = "GET_MARITIME_TRANSPORTS_FAIL";
export const ADD_MARITIME_TRANSPORT = "ADD_MARITIME_TRANSPORT";
export const ADD_MARITIME_TRANSPORT_SUCCESS =
  "ADD_MARITIME_TRANSPORT_SUCCESS";
export const ADD_MARITIME_TRANSPORT_FAIL =
  "ADD_MARITIME_TRANSPORT_FAIL";
export const EDIT_MARITIME_TRANSPORT =
  "EDIT_MARITIME_TRANSPORT";
export const EDIT_MARITIME_TRANSPORT_SUCCESS =
  "EDIT_MARITIME_TRANSPORT_SUCCESS";
export const EDIT_MARITIME_TRANSPORT_FAIL =
  "EDIT_MARITIME_TRANSPORT_FAIL";
export const DELETE_MARITIME_TRANSPORT =
  "DELETE_MARITIME_TRANSPORT";
export const DELETE_MARITIME_TRANSPORT_SUCCESS =
  "DELETE_MARITIME_TRANSPORT_SUCCESS";
export const DELETE_MARITIME_TRANSPORT_FAIL =
  "DELETE_MARITIME_TRANSPORT_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
