export const GET_AIR_PASSENGER_TRAFFIC = "GET_AIR_PASSENGER_TRAFFIC";
export const GET_AIR_PASSENGER_TRAFFIC_SUCCESS = "GET_AIR_PASSENGER_TRAFFIC_SUCCESS";
export const GET_AIR_PASSENGER_TRAFFIC_FAIL = "GET_AIR_PASSENGER_TRAFFIC_FAIL";
export const ADD_AIR_PASSENGER_TRAFFIC = "ADD_AIR_PASSENGER_TRAFFIC";
export const ADD_AIR_PASSENGER_TRAFFIC_SUCCESS = "ADD_AIR_PASSENGER_TRAFFIC_SUCCESS";
export const ADD_AIR_PASSENGER_TRAFFIC_FAIL = "ADD_AIR_PASSENGER_TRAFFIC_FAIL";
export const EDIT_AIR_PASSENGER_TRAFFIC = "EDIT_AIR_PASSENGER_TRAFFIC";
export const EDIT_AIR_PASSENGER_TRAFFIC_SUCCESS =
  "EDIT_AIR_PASSENGER_TRAFFIC_SUCCESS";
export const EDIT_AIR_PASSENGER_TRAFFIC_FAIL = "EDIT_AIR_PASSENGER_TRAFFIC_FAIL";
export const DELETE_AIR_PASSENGER_TRAFFIC = "DELETE_AIR_PASSENGER_TRAFFIC";
export const DELETE_AIR_PASSENGER_TRAFFIC_SUCCESS =
  "DELETE_AIR_PASSENGER_TRAFFIC_SUCCESS";
export const DELETE_AIR_PASSENGER_TRAFFIC_FAIL = "DELETE_AIR_PASSENGER_TRAFFIC_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
