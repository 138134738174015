export const GET_RAILWAYS_PASSENGERS = "GET_RAILWAYS_PASSENGERS";
export const GET_RAILWAYS_PASSENGERS_SUCCESS =
  "GET_RAILWAYS_PASSENGERS_SUCCESS";
export const GET_RAILWAYS_PASSENGERS_FAIL = "GET_RAILWAYS_PASSENGERS_FAIL";
export const ADD_RAILWAYS_PASSENGER = "ADD_RAILWAYS_PASSENGER";
export const ADD_RAILWAYS_PASSENGER_SUCCESS = "ADD_RAILWAYS_PASSENGER_SUCCESS";
export const ADD_RAILWAYS_PASSENGER_FAIL = "ADD_RAILWAYS_PASSENGER_FAIL";
export const BULK_UPLOAD_RAILWAYS_PASSENGER = "BULK_UPLOAD_RAILWAYS_PASSENGER";
export const BULK_UPLOAD_RAILWAYS_PASSENGER_SUCCESS =
  "BULK_UPLOAD_RAILWAYS_PASSENGER_SUCCESS";
export const BULK_UPLOAD_RAILWAYS_PASSENGER_FAIL =
  "BULK_UPLOAD_RAILWAYS_PASSENGER_FAIL";
export const EDIT_RAILWAYS_PASSENGER = "EDIT_RAILWAYS_PASSENGER";
export const EDIT_RAILWAYS_PASSENGER_SUCCESS =
  "EDIT_RAILWAYS_PASSENGER_SUCCESS";
export const EDIT_RAILWAYS_PASSENGER_FAIL = "EDIT_RAILWAYS_PASSENGER_FAIL";
export const DELETE_RAILWAYS_PASSENGER = "DELETE_RAILWAYS_PASSENGER";
export const DELETE_RAILWAYS_PASSENGER_SUCCESS =
  "DELETE_RAILWAYS_PASSENGER_SUCCESS";
export const DELETE_RAILWAYS_PASSENGER_FAIL = "DELETE_RAILWAYS_PASSENGER_FAIL";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
