export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const EDIT_PROFILE_IMAGE = "EDIT_PROFILE_IMAGE";
export const SEND_VERIFICATION_EMAIL = "SEND_VERIFICATION_EMAIL";
export const SEND_VERIFICATION_EMAIL_SUCCESS =
  "SEND_VERIFICATION_EMAIL_SUCCESS";
export const SEND_VERIFICATION_EMAIL_ERROR = "SEND_VERIFICATION_EMAIL_ERROR";
export const PROFILE_IMAGE_SUCCESS = "PROFILE_IMAGE_SUCCESS";
export const PROFILE_IMAGE_ERROR = "PROFILE_IMAGE_ERROR";
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
